body{
    background-color: rgb(240,240,240);
}

.primary-color{
    color: #00a49a;
}

.primary-bgcolor{
    background-color: #00a49a;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.main-container{
    background-image: url('assets/images/background/bg2.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

.product:hover .btn-remove-product {
    display: block;
}

.payment-input:has(input:checked){ 
    box-shadow: 1px 1px 3px gray;

}